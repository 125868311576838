<template>
    <div>
        <b-card no-body v-for="(checklist, checklistIndex) in checklists" :key="checklistIndex" class="mb-1">
            <b-card-header v-b-toggle="'checklist-' + checklist.id">
                <b-row>
                    <b-col>
                        {{ checklist.name }}
                    </b-col>
                    <b-col class="text-right" v-if="userIsSanitaryController">
                        {{ checklist.items.filter((item) => !!item.primary_status).length }} / {{
                            checklist.items.length
                        }}
                    </b-col>
                    <b-col class="text-right" v-else-if="userIsResponsible">
                        {{
                            checklist.items.filter((item) => [STATUS_RESPONSIBLE_DONE, STATUS_TRANSFERRED_TO_LONG_TERM].includes(item.primary_status)).length
                        }} / {{ checklist.items.length }}
                    </b-col>
                </b-row>
            </b-card-header>
            <b-collapse :id="'checklist-' + checklist.id" class="mt-2">
                <b-card-body>
                    <b-card no-body border-variant="dark" v-for="(item, index) in checklist.items" :key="index"
                            class="mb-1">
                        <b-card-header v-b-toggle="'item-' + item.id"
                                       :header-bg-variant="getHeaderVariant(item.primary_status)">
                            <b-row>
                                <b-col cols="6">
                                    {{ item.name }}
                                </b-col>
                            </b-row>
                        </b-card-header>
                        <b-collapse
                            :visible="!item.primary_status"
                            :id="'item-' + item.id"
                            class="mt-2"
                        >
                            <b-card-body>
                                <b-form-group
                                    label="Комментарий контролера"
                                >
                                    <b-form-textarea
                                        :disabled="!userIsSanitaryController"
                                        lazy
                                        v-model="item.comment_of_sanitary_controller"
                                    />
                                </b-form-group>
                                <b-form-group
                                    v-if="userIsResponsible"
                                    label="Комментарий ответственного"
                                >
                                    <b-form-textarea
                                        :disabled="!userIsResponsible || inspectionIsCompleted"
                                        lazy
                                        v-model="item.comment_of_responsible"
                                    />
                                </b-form-group>
                                <b-form-group
                                    v-if="!inspectionIsCompleted && (userIsSanitaryController || userIsResponsible)">
                                    <b-form-file
                                        v-model="item.photo"
                                        @input="uploadPhoto(item)"
                                        placeholder="Выберите файл"
                                        drop-placeholder="Drop file here..."
                                    />
                                </b-form-group>

                                <div class="img-container">
                                    <div v-for="image of item.images" class="mr-2">
                                        <div class="text-right">
                                            <b-button v-if="$auth.user().id === image.created_by" variant="default"
                                                      @click="destroyPhoto(item, image.id)">
                                                <b-icon-x-circle/>
                                            </b-button>
                                        </div>
                                        <b-img
                                            v-b-modal.modal-photo-scale-1
                                            @click="clickedPhotoUrl = image.url"
                                            :src="image.url"
                                            alt="Ошибка загрузки"
                                            width="100%"
                                            fluid="true"
                                            thumbnail
                                            center
                                        />
                                        <span class="my-3 text-break">{{ image.name }}</span>
                                    </div>
                                </div>

                                <b-form-group v-if="!inspectionIsCompleted && !isFinished && userIsSanitaryController"
                                              class="text-right">
                                    <sanitary-controller-button-group v-model="item.status" @input="update(item)"/>
                                </b-form-group>

                                <b-form-group
                                    v-if="!inspectionIsCompleted && isFinished && !correctingInconsistenciesIsFinished && allowedForResponsible(item)"
                                    class="text-right">
                                    <responsible-button-group v-model="item.status" @input="update(item)"/>
                                </b-form-group>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-row v-if="userIsSanitaryController && !inspectionIsCompleted && !isFinished">
            <b-col cols="12" class="text-right">
                <b-button @click="finish" variant="success">Завершить</b-button>
            </b-col>
        </b-row>

        <b-row v-if="userIsResponsible && !inspectionIsCompleted && isFinished && !correctingInconsistenciesIsFinished">
            <b-col cols="12" class="text-right">
                <b-button @click="finishCorrectingInconsistencies" variant="success">Завершить</b-button>
            </b-col>
        </b-row>

        <b-modal id="modal-photo-scale-1" size="xl" hide-footer scrollable>
            <b-img :src="clickedPhotoUrl" alt="Ошибка загрузки" fluid="true"/>
        </b-modal>
    </div>
</template>

<script>
import SanitaryControllerButtonGroup from './SanitaryControllerButtonGroup';
import ResponsibleButtonGroup from './ResponsibleButtonGroup';
import {ROLE_QUALITY_CONTROL, ROLE_SHIFT_SUPERVISOR, ROLE_STORAGE_SENIOR} from '@utils/Roles';
import ErrorsBag from '@utils/errorsBag';
import {PLANNED_SANITARY_INSPECTION_ITEMS_ENDPOINT} from '@utils/endpoints';

const STATUS_CORRESPOND = 'correspond';
const STATUS_NOT_CORRESPOND = 'not_correspond';
const STATUS_INAPPLICABLE = 'inapplicable';

const STATUS_RESPONSIBLE_DONE = 'responsible_done';
const STATUS_TRANSFERRED_TO_LONG_TERM = 'transferred_to_long_term';

export default {
    name: 'PlannedPrimaryInspection',
    props: {
        value: {
            type: Number,
            required: true,
        },
        isFinished: {
            type: Boolean,
            required: true,
            default: false,
        },
        inspectionIsCompleted: {
            type: Boolean,
            required: true,
            default: false,
        },
        correctingInconsistenciesIsFinished: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    data() {
        return {
            plannedSanitaryInspectionId: this.value,
            checklists: [],
            clickedPhotoUrl: null,
            STATUS_RESPONSIBLE_DONE,
            STATUS_TRANSFERRED_TO_LONG_TERM,
            STATUS_NOT_CORRESPOND,
        }
    },
    computed: {
        userIsSanitaryController() {
            const userHasShiftSupervisorRole = this.$auth.user().roles.includes(ROLE_SHIFT_SUPERVISOR);
            const userHasQualityControlRole = this.$auth.user().roles.includes(ROLE_QUALITY_CONTROL);
            const userIsAdmin = this.$auth.user().is_admin;

            if (userIsAdmin) {
                return true;
            }

            return userHasShiftSupervisorRole && userHasQualityControlRole || userHasQualityControlRole;
        },
        userIsResponsible() {
            return this.$auth.user().is_admin
                || (
                    this.$auth.user().roles.includes(ROLE_STORAGE_SENIOR)
                    && this.$auth.user().storagesGroupedByRole[ROLE_STORAGE_SENIOR]
                    && !this.$auth.user().roles.includes(ROLE_SHIFT_SUPERVISOR)
                );
        },
    },
    components: {
        SanitaryControllerButtonGroup,
        ResponsibleButtonGroup,
    },
    methods: {
        load() {
            const params = {
                filters: {
                    planned_sanitary_inspection_id: this.plannedSanitaryInspectionId,
                }
            }
            this.$http.get(
                `${PLANNED_SANITARY_INSPECTION_ITEMS_ENDPOINT}`,
                {
                    params
                }
            ).then((response) => {
                this.checklists = response.data;
                if (!this.checklists.length) {
                    this.$router.push({name: 'PlannedSanitaryInspections'});
                }
            })
        },
        finish() {
            ErrorsBag.discard();

            let itemsWithoutStatus = [];
            this.checklists.forEach((checklist) => {
                const filtered = checklist.items.filter((item) => !item.primary_status);
                if (filtered.length) {
                    itemsWithoutStatus.push(...filtered);
                }
            });

            if (itemsWithoutStatus.length > 0) {
                ErrorsBag.fill(['Нельзя завершить первичную проверку. Не все показатели проверены!']);
                window.scrollTo({top: 0, behavior: 'smooth'});
                return;
            }

            this.$emit('finish-event');
        },
        finishCorrectingInconsistencies() {
            let itemsWithoutStatus = [];
            this.checklists.forEach((checklist) => {
                const filtered = checklist.items.filter((item) => item.primary_status === STATUS_NOT_CORRESPOND);
                if (filtered.length) {
                    itemsWithoutStatus.push(...filtered);
                }
            });

            if (itemsWithoutStatus.length > 0) {
                ErrorsBag.fill(['Нельзя завершить устранение несоответсвий. Не все показатели проверены!']);
                window.scrollTo({top: 0, behavior: 'smooth'});
                return;
            }

            this.$emit('finish-correcting-inconsistencies-event');
        },
        update(item) {
            ErrorsBag.discard();

            this.$http.put(
                `${PLANNED_SANITARY_INSPECTION_ITEMS_ENDPOINT}/${item.id}`,
                {
                    primary_status: item.status,
                    comment_of_sanitary_controller: item.comment_of_sanitary_controller,
                    comment_of_responsible: item.comment_of_responsible,
                },
            ).then(async () => {
                this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                const response = await this.$http.get(`${PLANNED_SANITARY_INSPECTION_ITEMS_ENDPOINT}/${item.id}`);
                this.$set(item, 'id', response.data.id);
                this.$set(item, 'name', response.data.name);
                this.$set(item, 'primary_status', response.data.primary_status);
                this.$set(item, 'secondary_status', response.data.secondary_status);
                this.$set(item, 'comment_of_sanitary_controller', response.data.comment_of_sanitary_controller);
                this.$set(item, 'comment_of_responsible', response.data.comment_of_responsible);
                this.$set(item, 'images', response.data.images);
            }).catch((error) => {
                const errors = error.data.errors
                    ? Object.values(error.data.errors).flat()
                    : [error.data.message];

                ErrorsBag.fill(errors);
                window.scrollTo({top: 0, behavior: 'smooth'});
            });
        },
        uploadPhoto(item) {
            if (item.photo) {
                // Конверитируем файл в webp
                const image = new Image();
                image.onload = () => {
                    const canvas = document.createElement('canvas');

                    canvas.width = image.naturalWidth;
                    canvas.height = image.naturalHeight;

                    canvas.getContext('2d').drawImage(image, 0, 0);

                    canvas.toBlob((blob) => {
                        const fileName = item.photo.name.substr(0, item.photo.name.indexOf('.'));
                        const file = new File([blob], `${fileName}.webp`, { type: blob.type });

                        const data = new FormData();
                        data.append('photo', file);

                        this.$http.post(
                            `${PLANNED_SANITARY_INSPECTION_ITEMS_ENDPOINT}/${item.id}/photos`,
                            data,
                            {
                                headers: {
                                    'Content-type': 'multipart/form-data',
                                },
                            }
                        ).then(async () => {
                            this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                            const response = await this.$http.get(`${PLANNED_SANITARY_INSPECTION_ITEMS_ENDPOINT}/${item.id}/photos`);
                            item.images = response.data;
                        }).catch((error) => {
                            const errors = error.data.errors
                                ? Object.values(error.data.errors).flat()
                                : [error.data.message];

                            ErrorsBag.fill(errors);
                            window.scrollTo({top: 0, behavior: 'smooth'});
                        });
                    }, 'image/webp');
                };

                image.src = URL.createObjectURL(item.photo);
            }
        },
        destroyPhoto(item, photoId) {
            ErrorsBag.discard();

            this.$http.delete(
                `${PLANNED_SANITARY_INSPECTION_ITEMS_ENDPOINT}/${item.id}/photos/${photoId}`,
            ).then(async () => {
                this.$bvToast.toast('Успешно сохранено', {variant: 'success'});

                const response = await this.$http.get(`${PLANNED_SANITARY_INSPECTION_ITEMS_ENDPOINT}/${item.id}/photos`);
                item.images = response.data;
            }).catch((error) => {
                const errors = error.data.errors
                    ? Object.values(error.data.errors).flat()
                    : [error.data.message];

                ErrorsBag.fill(errors);
                window.scrollTo({top: 0, behavior: 'smooth'});
            });
        },
        allowedForResponsible(item) {
            return item.primary_status
                && [STATUS_NOT_CORRESPOND, STATUS_RESPONSIBLE_DONE, STATUS_TRANSFERRED_TO_LONG_TERM].includes(item.primary_status)
                && this.userIsResponsible;
        },
        getHeaderVariant(status) {
            let variant = '';
            if (this.userIsSanitaryController) {
                switch (status) {
                    case STATUS_CORRESPOND:
                        variant = 'success';
                        break;
                    case STATUS_TRANSFERRED_TO_LONG_TERM:
                    case STATUS_RESPONSIBLE_DONE:
                    case STATUS_NOT_CORRESPOND:
                        variant = 'danger';
                        break;
                    case STATUS_INAPPLICABLE:
                        variant = 'warning';
                        break;
                }
            } else if (this.userIsResponsible) {
                switch (status) {
                    case STATUS_RESPONSIBLE_DONE:
                        variant = 'success';
                        break;
                    case STATUS_TRANSFERRED_TO_LONG_TERM:
                        variant = 'secondary';
                        break;
                }
            }

            return variant;
        },
    },
    mounted() {
        if (this.plannedSanitaryInspectionId) {
            this.load();
        }
    }
}
</script>

<style scoped>
.img-container {
    display: grid;
    justify-content: space-evenly;
    grid-template-columns : repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}
</style>
