<template>
    <b-button-group>
        <b-button @click="input(STATUS_CORRESPOND)" variant="success">Соответствует</b-button>
        <b-button @click="input(STATUS_NOT_CORRESPOND)" variant="danger">Не соответствует</b-button>
        <b-button @click="input(STATUS_INAPPLICABLE)" variant="warning">Не применимо</b-button>
    </b-button-group>
</template>

<script>
    const STATUS_CORRESPOND = 'correspond';
    const STATUS_NOT_CORRESPOND = 'not_correspond';
    const STATUS_INAPPLICABLE = 'inapplicable';

    export default {
        name: 'SanitaryControllerButtonGroup',
        data() {
            return {
                STATUS_CORRESPOND,
                STATUS_NOT_CORRESPOND,
                STATUS_INAPPLICABLE,
            }
        },
        methods: {
            input(status) {
                this.$emit('input', status);
            }
        }

    }
</script>
