<template>
    <div>
        <errors-bag-list/>
        <b-card no-body>
            <b-card-header>
                <b-row>
                    <b-col cols="6">{{ form.name }}</b-col>
                    <b-col
                        v-if="userIsSanitaryController"
                        cols="6"
                        class="text-right"
                    >
                        <b-link
                            :to="{path: `/planning-sanitary-inspections/${form.planning_sanitary_inspection_id}`}"
                        >
                            Создана из планерки №{{ form.planning_sanitary_inspection_id }}
                        </b-link>
                    </b-col>
                </b-row>
                <h3 v-if="!!form.primary_finished_at" class="text-center">Балл первичной
                    проверки: {{ form.act.score }}</h3>
                <h3 v-if="!!form.secondary_finished_at" class="text-center">Балл вторичной
                    проверки: {{ form.act.additional_score }}</h3>
                <p v-if="!userIsSanitaryController && form.primary_finished_at && !form.correcting_inconsistencies_finished_at">
                    Дата и время на устранение несоответствия:
                    {{ moment(form.primary_finished_at).add(12, 'hours').format('DD.MM.YYYY HH:MM:SS') }} (Осталось:
                    {{ remainingTimeToCorrectingInconsistencies }})
                </p>
            </b-card-header>
        </b-card>

        <b-card no-body v-if="isStarted || isCompleted">
            <b-tabs card fill>
                <b-tab :title="tabTitle">
                    <planned-primary-inspection
                        v-model.number="form.id"
                        :is-finished="!!form.primary_finished_at"
                        :correcting-inconsistencies-is-finished="!!form.correcting_inconsistencies_finished_at || remainingTimeToCorrectingInconsistencies === 0"
                        :inspection-is-completed="isCompleted"
                        @finish-event="finishPrimaryInspection"
                        @finish-correcting-inconsistencies-event="finishCorrectingInconsistencies"
                    />
                </b-tab>
                <b-tab
                    v-if="!!form.primary_finished_at && userIsSanitaryController"
                    title="Вторичная проверка"
                    :active="!!form.primary_finished_at"
                >
                    <planned-secondary-inspection
                        v-model.number="form.id"
                        :is-finished="!!form.secondary_finished_at"
                        @finish-event="finishSecondaryInspection"
                    />
                </b-tab>
            </b-tabs>
        </b-card>

        <b-row v-if="userIsSanitaryController">
            <b-col class="text-right">
                <b-button-group>
                    <b-button
                        v-if="!isStarted && !isCompleted && allowedToStart"
                        @click="start"
                        variant="primary"
                        class="mr-2"
                    >
                        Начать проверку
                    </b-button>
                    <b-button
                        v-if="!!form.primary_finished_at && !!form.secondary_finished_at && !isCompleted"
                        @click="complete"
                        variant="success"
                        class="mr-2"
                    >
                        Закончить проверку
                    </b-button>
                    <b-button
                        v-if="!form.status"
                        v-b-modal.reschedule-modal
                        variant="warning"
                        class="mr-2"
                    >
                        Перенести
                    </b-button>
                </b-button-group>
            </b-col>
        </b-row>

        <b-modal id="reschedule-modal" centered title="Перенос проверки" @ok="reschedule">
            <b-row>
                <b-col class="text-center">
                    <b-calendar
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        :start-weekday="1"
                        :min="moment().format('YYYY-MM-DD')"
                        locale="ru"
                        v-model="form.started_at"
                    />
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import {PLANNED_SANITARY_INSPECTIONS_ENDPOINT} from '@utils/endpoints';

import ErrorsBagList from '@components/_common/ErrorsBagList';

import PlannedPrimaryInspection                      from './components/PlannedPrimaryInspection';
import PlannedSecondaryInspection                    from './components/PlannedSecondaryInspection';
import {ROLE_QUALITY_CONTROL, ROLE_SHIFT_SUPERVISOR} from '@utils/Roles';
import moment                                        from 'moment';

const STATUS_STARTED = 'started';
const STATUS_COMPLETED = 'completed';

export default {
    name: 'Form',
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            form: {
                id: this.id,
                status: null,
                name: null,
                planning_sanitary_inspection_id: null,
                primary_finished_at: null,
                secondary_finished_at: null,
                correcting_inconsistencies_finished_at: null,
                checklists: null,
                act: {
                    score: null,
                    additional_score: null,
                },
                started_at: null,
            },
            primaryInspectionIsFinished: false,
            secondaryInspectionIsFinished: false,
            correctingInconsistenciesIsFinished: false,
            moment,
            rescheduled: false,
            remainingTimeToCorrectingInconsistencies: 0,
            interval: null,
        }
    },
    components: {
        ErrorsBagList,
        PlannedPrimaryInspection,
        PlannedSecondaryInspection,
    },
    computed: {
        userIsSanitaryController() {
            const userHasShiftSupervisorRole = this.$auth.user().roles.includes(ROLE_SHIFT_SUPERVISOR);
            const userHasQualityControlRole = this.$auth.user().roles.includes(ROLE_QUALITY_CONTROL);
            const userIsAdmin = this.$auth.user().is_admin;

            if (userIsAdmin) {
                return true;
            }

            return userHasShiftSupervisorRole && userHasQualityControlRole || userHasQualityControlRole;
        },
        isStarted() {
            return this.form.status && this.form.status === STATUS_STARTED;
        },
        isCompleted() {
            return this.form.status && this.form.status === STATUS_COMPLETED;
        },
        allowedToStart() {
            return moment(this.form.started_at).isSame(moment().format('YYYY-MM-DD'));
        },
        tabTitle() {
            return this.userIsSanitaryController ? 'Первичная проверка' : 'Задачи на устранение несоответствий';
        },
    },
    methods: {
        load() {
            const params = {
                with: [
                    'act',
                ],
                filters: {
                    without_conditions: true,
                }
            };

            this.$http.get(`${PLANNED_SANITARY_INSPECTIONS_ENDPOINT}/${this.form.id}`, {params})
                .then((response) => this.form = response.data.data);
        },
        start() {
            this.form.status = STATUS_STARTED;
            this.update();
        },
        complete() {
            this.form.status = STATUS_COMPLETED;
            this.update();
        },
        reschedule() {
            this.rescheduled = true;
            this.update();
        },
        finishPrimaryInspection() {
            this.primaryInspectionIsFinished = true;
            this.update();
        },
        finishSecondaryInspection() {
            this.secondaryInspectionIsFinished = true;
            this.update();
        },
        finishCorrectingInconsistencies() {
            this.correctingInconsistenciesIsFinished = true;
            this.update();
        },
        update() {
            this.$http.put(
                `${PLANNED_SANITARY_INSPECTIONS_ENDPOINT}/${this.form.id}`,
                {
                    status: this.form.status,
                    started_at: this.form.started_at,
                    primary_inspection_is_finished: this.primaryInspectionIsFinished,
                    secondary_inspection_is_finished: this.secondaryInspectionIsFinished,
                    correcting_inconsistencies_is_finished: this.correctingInconsistenciesIsFinished,
                }
            ).then(() => {
                if (this.rescheduled || this.correctingInconsistenciesIsFinished) {
                    this.$router.push({name: 'PlannedSanitaryInspections'});
                }

                this.load();
                this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
            });
        },
        updateRemainingTimeToCorrectingInconsistencies() {
            const now = moment(new Date());
            const end = moment(this.form.primary_finished_at).add(12, 'hours');

            if ((end - now) < 0) {
                this.remainingTimeToCorrectingInconsistencies = 0;
                clearInterval(this.interval);
            } else {
                this.remainingTimeToCorrectingInconsistencies = moment.utc(moment.duration(end) - moment.duration(now)).format('HH:mm:ss');
            }
        }
    },
    mounted() {
        this.load();
        if (!this.userIsSanitaryController) {
            this.interval = setInterval(this.updateRemainingTimeToCorrectingInconsistencies, 1000);
        }
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
}
</script>
