<template>
    <b-button-group>
        <b-button @click="input(STATUS_RESPONSIBLE_DONE)" variant="success">Выполнено</b-button>
        <b-button @click="input(STATUS_TRANSFERRED_TO_LONG_TERM)" variant="secondary">Передать в долгосрок</b-button>
    </b-button-group>
</template>

<script>
    const STATUS_RESPONSIBLE_DONE = 'responsible_done';
    const STATUS_TRANSFERRED_TO_LONG_TERM = 'transferred_to_long_term';

    export default {
        name: 'ResponsibleButtonGroup',
        data() {
            return {
                STATUS_RESPONSIBLE_DONE,
                STATUS_TRANSFERRED_TO_LONG_TERM,
            }
        },
        methods: {
            input(status) {
                this.$emit('input', status);
            }
        }
    }
</script>
