<template>
    <div>
        <b-card no-body v-for="(checklist, checklistIndex) in checklists" :key="checklistIndex" class="mb-1">
            <b-card-header v-b-toggle="'checklist-' + checklist.id">
                <b-row>
                    <b-col>
                        {{ checklist.name }}
                    </b-col>
                    <b-col class="text-right">
                        {{ checklist.items.filter((item) => !!item.secondary_status).length }} /
                        {{ checklist.items.length }}
                    </b-col>
                </b-row>
            </b-card-header>
            <b-collapse :id="'checklist-' + checklist.id" class="mt-2">
                <b-card-body>
                    <b-card no-body border-variant="dark" v-for="(item, index) in checklist.items" :key="index"
                            class="mb-1">
                        <b-card-header v-b-toggle="'item-' + item.id"
                                       :header-bg-variant="getHeaderVariant(item.secondary_status)">
                            <b-row>
                                <b-col cols="6">{{ item.name }}</b-col>
                                <b-col v-if="item.primary_status === STATUS_TRANSFERRED_TO_LONG_TERM" cols="6"
                                       class="text-right">
                                    <b-badge variant="warning">Передано в долгосрок</b-badge>
                                </b-col>
                                <b-col v-if="item.primary_status === STATUS_NOT_CORRESPOND" cols="6" class="text-right">
                                    <b-badge variant="danger">Просрочено</b-badge>
                                </b-col>
                            </b-row>
                        </b-card-header>
                        <b-collapse
                            :visible="!item.secondary_status"
                            :id="'item-' + item.id"
                            class="mt-2"
                        >
                            <b-card-body>
                                <b-form-group
                                    label="Комментарий контролера"
                                >
                                    <b-form-textarea
                                        :disabled="true"
                                        lazy
                                        v-model="item.comment_of_sanitary_controller"
                                    />
                                </b-form-group>
                                <b-form-group
                                    v-if="item.comment_of_responsible"
                                    label="Комментарий ответственного"
                                >
                                    <b-form-textarea
                                        :disabled="true"
                                        lazy
                                        v-model="item.comment_of_responsible"
                                    />
                                </b-form-group>

                                <div class="img-container">
                                    <div v-for="image of item.images" class="m-2">
                                        <b-img
                                            v-b-modal.modal-photo-scale-2
                                            @click="clickedPhotoUrl = image.url"
                                            :src="image.url"
                                            alt="Ошибка загрузки"
                                            width="100%"
                                            fluid="true"
                                            thumbnail
                                            center
                                        />
                                        <span class="my-3 text-break">{{ image.name }}</span>
                                    </div>
                                </div>

                                <b-form-group
                                    v-if="userIsSanitaryController && !isFinished && item.primary_status !== STATUS_TRANSFERRED_TO_LONG_TERM && item.primary_status !== STATUS_NOT_CORRESPOND"
                                    class="text-right">
                                    <b-button-group>
                                        <b-button @click="setStatus(item, STATUS_CORRESPOND)" variant="success">
                                            Соответствует
                                        </b-button>
                                        <b-button @click="setStatus(item, STATUS_NOT_CORRESPOND)" variant="danger">Не
                                            соответствует
                                        </b-button>
                                    </b-button-group>
                                </b-form-group>

                                <b-form-group
                                    v-if="userIsSanitaryController && !isFinished && item.primary_status === STATUS_TRANSFERRED_TO_LONG_TERM"
                                    class="text-right">
                                    <b-button-group>
                                        <b-button @click="setStatus(item, STATUS_RESOLVE_TRANSFER_TO_LONG_TERM)"
                                                  variant="success">Подтвердить
                                        </b-button>
                                        <b-button @click="setStatus(item, STATUS_REJECT_TRANSFER_TO_LONG_TERM)"
                                                  variant="danger">Отклонить
                                        </b-button>
                                    </b-button-group>
                                </b-form-group>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-row v-if="!isFinished && checklists.length && userIsSanitaryController">
            <b-col cols="12" class="text-right">
                <b-button @click="finish" variant="success">Завершить</b-button>
            </b-col>
        </b-row>

        <b-modal id="modal-photo-scale-2" size="xl" hide-footer>
            <b-img :src="clickedPhotoUrl" alt="Ошибка загрузки" fluid="true"/>
        </b-modal>
    </div>
</template>

<script>
import ErrorsBag                                    from '@utils/errorsBag';
import {PLANNED_SANITARY_INSPECTION_ITEMS_ENDPOINT} from '@utils/endpoints';

import {ROLE_QUALITY_CONTROL, ROLE_SHIFT_SUPERVISOR} from '@utils/Roles';

const STATUS_CORRESPOND = 'correspond';
const STATUS_NOT_CORRESPOND = 'not_correspond';

const STATUS_RESOLVE_TRANSFER_TO_LONG_TERM = 'resolve_transfer_to_long_term';
const STATUS_REJECT_TRANSFER_TO_LONG_TERM = 'reject_transfer_to_long_term';

const STATUS_TRANSFERRED_TO_LONG_TERM = 'transferred_to_long_term';

export default {
    name: 'PlannedSecondaryInspection',
    props: {
        value: {
            type: Number,
            required: true,
        },
        isFinished: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    data() {
        return {
            plannedSanitaryInspectionId: this.value,
            checklists: [],
            clickedPhotoUrl: null,
            STATUS_CORRESPOND,
            STATUS_NOT_CORRESPOND,
            STATUS_TRANSFERRED_TO_LONG_TERM,
            STATUS_RESOLVE_TRANSFER_TO_LONG_TERM,
            STATUS_REJECT_TRANSFER_TO_LONG_TERM,
        }
    },
    computed: {
        userIsSanitaryController() {
            const userHasShiftSupervisorRole = this.$auth.user().roles.includes(ROLE_SHIFT_SUPERVISOR);
            const userHasQualityControlRole = this.$auth.user().roles.includes(ROLE_QUALITY_CONTROL);
            const userIsAdmin = this.$auth.user().is_admin;

            if (userIsAdmin) {
                return true;
            }

            return userHasShiftSupervisorRole && userHasQualityControlRole || userHasQualityControlRole;
        },
    },
    methods: {
        load() {
            const params = {
                filters: {
                    planned_sanitary_inspection_id: this.plannedSanitaryInspectionId,
                    secondary_inspection: true,
                }
            }

            this.$http.get(
                `${PLANNED_SANITARY_INSPECTION_ITEMS_ENDPOINT}`,
                {
                    params
                }
            ).then((response) => this.checklists = response.data);
        },
        setStatus(item, status) {
            item.secondary_status = status;
            this.update(item);
        },
        finish() {
            ErrorsBag.discard();

            let itemsWithoutStatus = [];
            this.checklists.forEach((checklist) => {
                const filtered = checklist.items.filter((item) => !item.secondary_status);
                if (filtered.length) {
                    itemsWithoutStatus.push(...filtered);
                }
            });

            if (itemsWithoutStatus.length > 0) {
                ErrorsBag.fill(['Нельзя завершить вторичную проверку. Не все показатели проверены!']);
                window.scrollTo({top: 0, behavior: 'smooth'});
                return;
            }

            this.$emit('finish-event');
        },
        update(item) {
            ErrorsBag.discard();
            this.$http.put(
                `${PLANNED_SANITARY_INSPECTION_ITEMS_ENDPOINT}/${item.id}`,
                {
                    secondary_status: item.secondary_status,
                    comment_of_sanitary_controller: item.comment_of_sanitary_controller,
                    comment_of_responsible: item.comment_of_responsible,
                    comment_of_responsible_aho: item.comment_of_responsible_aho,
                }
            ).then(() => {
                this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                this.load();
            }).catch((error) => {
                const errors = error.data.errors
                    ? Object.values(error.data.errors).flat()
                    : [error.data.message];

                ErrorsBag.fill(errors);
                window.scrollTo({top: 0, behavior: 'smooth'});
            });
        },
        getHeaderVariant(status) {
            let variant = '';
            switch (status) {
                case STATUS_RESOLVE_TRANSFER_TO_LONG_TERM:
                case STATUS_CORRESPOND:
                    variant = 'success';
                    break;
                case STATUS_REJECT_TRANSFER_TO_LONG_TERM:
                case STATUS_NOT_CORRESPOND:
                    variant = 'danger';
                    break;
            }

            return variant;
        },
    },
    mounted() {
        if (this.plannedSanitaryInspectionId) {
            this.load();
        }
    }
}
</script>

<style scoped>
.img-container {
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}
</style>
